import * as React from "react"
import Layout from '../components/Layout'
import SEO from "../components/seo"
import HomeComp from "../components/Home";
import PuntaMar from '../components/PuntaMar';
import LayoutPages from '../components/Layout/layoutPages';

const IndexPage = (props) => {
  return (
    <Layout>
      <SEO
        title="The Ocean Group"
        description="Ofrecemos oportunidades inmobiliarias en la costa de Oaxaca"
        works=''
      />

      <HomeComp {...props} />
      {/* <PuntaMar {...props}/> */}

    </Layout>
  )
}

export default IndexPage
